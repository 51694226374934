<template>
  <div class="p-4 root relative block">
    <div class="font-h font-semibold text-lg text-primary">
      Exported Files
    </div>

    <!-- empty state -->
    <div v-if="!latestExportFiles.length" class="flex flex-col list mt-36 text-primary-500 items-center">
      <div>
        <FileAlertIcon />
      </div>
      <div class="text-sm text-center leading-6 w-56">
        No exports found 😢
      </div>
    </div>
    <!-- File list -->
    <div else class="list mt-6 pr-3 pb-10 overflow-y-auto">
      <div
        v-for="file in latestExportFiles"
        :key="file.key"
        class="border rounded flex border-primary-300 mb-2.5 py-3.5 px-4 items-center justify-between group hover:border-primary"
        :class="{'cursor-pointer': file.status==='done' || file.status==='error'}"
        @click="downloadURL(file, file.key)"
      >
        <div class="text-sm">
          {{fileTypeMapping[file.key]}}
        </div>

        <div class="flex items-center">
          <div v-if="file.status==='start' || file.status==='process'" class="flex text-xs text-green items-center">
          In progress...<CircularLoader classname="ml-2" />
          </div>
          <div v-if="file.status==='error'" class="text-xs text-red mr-2">
            Failed
          </div>
          <div v-if="file.status==='done'" class="text-xs mr-2 hidden group-hover:block">
            <div class="text-green">Completed</div>
            <div class="text-primary-500">On {{moment(file.time).format('DD MMM, YYYY')}}</div>
          </div>

          <div
            v-if="file.status!=='start' && file.status!=='process'"
            :class="['h-8 text-primary relative flex flex-col items-center', file.status === 'done' ? 'cursor-pointer' : '']"
          >
            <MediaFileIcon />

            <div
              class="font-bold text-white top-3 absolute" style="font-size:7px;line-height: 8px;"
            >
              {{fileExtMapping[file.key]}}
            </div>

            <div
              v-show="file.status === 'done'"
              class="bg-green border border-white rounded-full flex h-4 text-white top-4.5 -left-1 w-4 absolute items-center justify-center"
            >
              <DownloadIcon />
            </div>

            <div
              v-show="file.status === 'error'"
              class="bg-red border border-white rounded-full flex h-4 text-white top-4.5 -left-1 w-4 absolute items-center justify-center"
            >
              <CloseIcon classname="h-2.5 w-2.5" />
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import * as firebase from 'firebase/app'
import 'firebase/storage'
import { mapGetters, mapActions } from 'vuex'
import CircularLoader from '@/components/base/icons/CircularLoader.vue'
import MediaFileIcon from '@/components/base/icons/MediaFile.vue'
import DownloadIcon from '@/components/base/icons/Download.vue'
import CloseIcon from '@/components/base/icons/Close.vue'
import FileAlertIcon from '@/components/base/icons/FileAlert.vue'
import moment from 'moment'

const fileTypeMapping = {
  'wav': 'WAV',
  'flac': 'FLAC',
  'mp3': 'MP3',
  'ogg': 'OGG',
  'post_production_wav': 'Post Produced WAV',
  'post_production_flac': 'Post Produced FLAC',
  'post_production_mp3': 'Post Produced MP3',
  'post_production_ogg': 'Post Produced OGG',
  'pdf': 'PDF',
  'srt': 'SRT',
  'vtt': 'VTT',
  'doc': 'DOCX',
  'redact_audio': 'Redacted Audio',
  'redact_video': 'Redacted Video',
  'post_production': 'Post Produced File',
  'sesx': 'Adobe Audition Session',
  'mp4': 'MP4'
}

const fileExtMapping = {
  'wav': 'WAV',
  'flac': 'FLAC',
  'mp3': 'MP3',
  'ogg': 'OGG',
  'post_production_wav': 'WAV',
  'post_production_flac': 'FLAC',
  'post_production_mp3': 'MP3',
  'post_production_ogg': 'OGG',
  'pdf': 'PDF',
  'srt': 'SRT',
  'vtt': 'VTT',
  'doc': 'DOCX',
  'sesx': 'SESX',
  'mp4': 'MP4'
}

export default {
  data: function() {
    return {
      moment,
      fileTypeMapping,
      fileExtMapping
    }
  },
  components: {
    CircularLoader,
    MediaFileIcon,
    DownloadIcon,
    CloseIcon,
    FileAlertIcon
  },
  props: {

  },
  computed: {
    ...mapGetters({
      'podcastInfo': 'editor/podcastInfo'
    }),
    latestExportFiles: function() {
      const exportFormats = Object.keys(fileTypeMapping)
      const rawFiles = this.podcastInfo.exported_files
      const groupedFiles = {}
      let version, type

      for (version in rawFiles) {
        for (type in rawFiles[version]) {
          if (exportFormats.includes(type)) {
            if (groupedFiles[type]) {
              groupedFiles[type].push({
                key: type,
                ...rawFiles[version][type]
              })
            } else {
              groupedFiles[type] = [{
                key: type,
                ...rawFiles[version][type]
              }]
            }
          }
        }
      }
      for (type in groupedFiles) {
        groupedFiles[type] = groupedFiles[type].sort((current, next) => (dayjs(next.time).isAfter(dayjs(current.time)) ? 1 : -1))
      }

      let latestFiles = []
      for (type in groupedFiles) {
        latestFiles.push(groupedFiles[type][0])
      }
      return latestFiles.sort((current, next) => (dayjs(current.time).isAfter(dayjs(next.time)) ? 1 : -1))
    }
  },
  methods: {
    downloadURL (data, type) {
      if (data.status === 'done') {
        const url = data.url
        console.log(url)
        if (url.indexOf('http') >= 0) {
          var a = document.createElement('a')
          a.href = url
          a.target = '_blank'
          a.download = 'export.' + type
          a.click()
        } else {
          const storage = firebase.storage()
          const httpsReference = storage.ref(url)
          httpsReference.getDownloadURL().then(url => {
            const a = document.createElement('a')
            a.href = url
            a.download = this.podcastInfo.title + '.' + type
            a.target = '_blank'
            a.click()
          }).catch(function (err) {
            console.log(err)
          })
        }
      } else if (data.status === 'error') {
        Intercom('showNewMessage', 'I am having trouble in exporting.')
      }
    }
  }
}
</script>

<style scoped lang="css">
.root {
  height: 100%;
}

.list {
  height: calc(100% - 36px);
}
</style>
